



























import {defineComponent} from '@vue/composition-api';
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import STextarea from '@/common/components/STextarea.vue';

export default defineComponent({
  name: 'Chem51LBProject2FSTheory',
  components: {STextarea, StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        theoryAnswer1: null,
        theoryAnswer2: null,
        theoryAnswer3: null,
        theoryAnswer4: null,
        theoryAnswer5: null,
        theoryAnswer6: null,
      },
      questions: [
        {
          text: 'What are the main differences between substitution and elimination reactions? Consider the types of reactants and conditions required and the types of bonds formed. ',
          inputName: 'theoryAnswer1',
          maxInputLength: 1000,
        },
        {
          text: 'What are the different types of substitution reactions and what mechanistically defines each type? ',
          inputName: 'theoryAnswer2',
          maxInputLength: 4000,
        },
        {
          text: 'What are the different types of elimination reactions and what mechanistically defines each type? ',
          inputName: 'theoryAnswer3',
          maxInputLength: 4000,
        },
        {
          text: 'What conditions favor elimination? What conditions favor substitution? Explain why.',
          inputName: 'theoryAnswer4',
          maxInputLength: 4000,
        },
        {
          text: 'In each reaction your team conducted in the Fundamental skills portion of the lab, what was the nucleophile and/or base? What was the electrophile?',
          inputName: 'theoryAnswer5',
          maxInputLength: 4000,
        },
        {
          text: 'Specifically explain how gas chromatography allows you to determine the ratio of substitution and elimination product. What allows you to determine which peak elutes first? ',
          inputName: 'theoryAnswer6',
          maxInputLength: 4000,
        },
      ],
    };
  },
});
